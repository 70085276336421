import React from 'react';
import { Layout, SEO } from '../components/common';

const NotFoundPage: React.FC = () => (
  <Layout color="#235FB2" noFooter>
    <SEO title="Home" />
  </Layout>
);

export default NotFoundPage;
